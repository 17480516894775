<template>
    <BT-Blade-Item
        bladeName="courier-action"
        :bladesData="bladesData"
        :canEdit="false"
        navigation="courier-actions"
        :onGetSave="(bladeData, item) => { return { functionName: removeSpaces(item.functionName) } }">
        <template v-slot="{ item, data }">

            <BT-Field-Select
                v-if="data.isNew"
                isEditing
                :items="['Auto Deliver Movements']"
                label="Action Name"
                v-model="item.functionName" />
            <v-list-item v-else>
                <v-list-item-content>
                    <v-list-item-subtitle>Action Name</v-list-item-subtitle>
                    <v-list-item-title>{{ item.functionName }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <BT-Field-Date
                v-if="!data.isNew"
                label="Started On"
                shortDateAndTime
                v-model="item.startedOn" />

            <BT-Field-Date
                v-if="!data.isNew"
                label="Finished On"
                shortDateAndTime
                v-model="item.finishedOn" />

            <v-list-item v-if="!data.isNew">
                <v-list-item-content>
                    <v-list-item-subtitle>Status</v-list-item-subtitle>
                    <v-list-item-title>
                        <span v-if="!isMinDate(item.finishedOn)">{{ item.isSuccess ? 'Succeeded' : 'Failed' }}</span>
                        <v-progress-circular v-else indeterminate size="16" />
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="!data.isNew">
                <v-list-item-content>
                    <v-list-item-subtitle>Message</v-list-item-subtitle>
                    <div>***{{ item.message }}</div>
                </v-list-item-content>
            </v-list-item>

        </template>
    </BT-Blade-Item>
</template>

<script>
export default {
    name: 'Courier-Action-Blade',
    props: {
        bladesData: null
    }
}
</script>